.userlist {
  margin: 2rem;
  width: 28rem;
}

.userHeader {
  width: inherit;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.tableHeaderuserData {
  //width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid black;
  height: 1.5rem;
  margin-bottom: 1rem;
}

.tableHeaderuserData p {
  width: 10rem;
  margin-right: 1rem;
}

.tableHeader {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid black;
  height: 1.5rem;
  margin-bottom: 1rem;
}

.tableHaeder p {
  font-size: 0.5rem;
  width: 10rem;
}

.userItem {
  width: 28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px black solid;
  height: 2rem;
}

.userData {
  display: flex;
  flex-direction: row;
}

.userData p {
  font-size: 1.1rem;
  width: 10rem;
  margin-right: 1rem;
  overflow: hidden;
}

.userData p:nth-child(2) {
  width: 12rem;
}

.checkmarkUser {
  /* position: absolute;
  top: 0;
  right: 0; */
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  border: 0.125rem solid black;
  background-color: white;
}

.container:hover input ~ .checkmarkUser {
  border: 0.125rem solid black;
  background-color: lightgrey;
}

.container input:checked ~ .checkmarkUser {
  background-color: black;
}

.container:hover input:checked ~ .checkmarkUser {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkUser:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmarkUser:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmarkUser:after {
  right: 0.405rem;
  top: 0.54rem;
  width: 0.25rem;
  height: 0.5rem;
  border: solid white;
  border-width: 0 0.1875rem 0.1875rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

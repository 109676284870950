:root {
  --arrowbase: 0.4rem;
  --arrowpeak: 0.3rem;
}

html {

  background-color: var(--subcolor)
}

button {
  background-color: var(--maincolor);
  border: none;
  height: 2rem;
  color: white;
  font-family: "DillonRegular", Helvetica, sans-serif;
  font-size: 1.25rem;
  margin-left: 0;
  margin-right: 0.25rem;
  width: 7rem;
}

button:hover {
  background-color: var(--hovercolor);
}

button:focus {
  outline: none;
}

.wrapper {
  //max-width: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* overflow: hidden; */
  background-color: var(--subcolor);
}

.grid {
  position: relative;
  //width:100%;
  display: flex;
  margin-left: 24rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.topInput {
  display: flex;
  width: 900px;
  //flex-direction: row;
  //justify-content: space-between;
  //width: 100%;
  //overflow: hidden
}
.subElement {
  opacity: 0;
  transition: margin-left 0.5s, opacity 0.1s 0.1s;
  width: auto;
  align-items: flex-start;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.subElementBottom {
  background-color: green;
  margin-top: auto;
  align-self: flex-end;
}
.rowselector {
  text-align: left;
  align-self: flex-start;
}

.gridelements {
  /* //margin-top:3em;*/
  margin-bottom:3em;
  width: 100%;
  display: inline;
  /* flex-direction: row;
  justify-content: center;
  align-items: center; */
}

.gridElement {
  position: relative;
}

.needle {
  position: absolute;
  margin-left: -0.125rem;
  top: 14.625rem;
  width: 0.3125rem;
  height: 31.25rem;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  display: none;
}

.needleMarker {
  margin-left: 0.0833rem;
  width: 0.125rem;
  height: inherit;
  background-color: var(--maincolor);
}
.myFileInput {
  display: none;
}

.envelopCanvas {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.eCanvas {
}

.handle {
  position: absolute;
  box-sizing: content-box;
  pointer-events: all;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;

  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0px;
  background-color: rgba(10, 10, 10, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  /* opacity: 0.5; */
}

.handleMiddleColumn {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
}

/* --arrowbase: 0.2rem;
--arrowpeak: 0.25rem; */

.handleArrowLeft {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  border-top: var(--arrowpeak) solid transparent;
  border-bottom: var(--arrowpeak) solid transparent;
  border-right: var(--arrowbase) solid var(--maincolor);
}

.handleArrowTop {
  opacity: 0.4;
  pointer-events: none;
  width: 0;
  height: 0;
  border-left: var(--arrowpeak) solid transparent;
  border-right: var(--arrowpeak) solid transparent;
  border-bottom: var(--arrowbase) solid var(--maincolor);
}

.handleArrowRight {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  border-top: var(--arrowpeak) solid transparent;
  border-bottom: var(--arrowpeak) solid transparent;
  border-left: var(--arrowbase) solid var(--maincolor);
}
.handleArrowBottom {
  pointer-events: none;
  width: 0;
  height: 0;
  border-left: var(--arrowpeak) solid transparent;
  border-right: var(--arrowpeak) solid transparent;
  border-top: var(--arrowbase) solid var(--maincolor);
}

.handlePoint {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  pointer-events: none;
  border: solid 2.5px var(--maincolor);
  background-color: rgba(255, 255, 255, 1);
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.3rem;
}

.fileConsole {
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  background-color: white;
  /* padding-top: 15rem; */
  height: 100%;
  width: 20em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fileConsoleButton {
  pointer-events: all;
  //background-color: blue;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.open {
  background-image: url("../assets/open.svg");
}

.save {
  background-image: url("../assets/save.svg");
}

.consoleButtonBar {
  pointer-events: all;
  display: flex;
  flex-direction: column;
}

.fileConsoleCloseButton {
  pointer-events: all;
  background-image: url("../assets/close.svg");
  width: 30px;
  height: 30px;
}

.fileConsoleHeader {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.fileConsoleContent {
  pointer-events: all;
  background-color: blue;
  height: 100%;
  width: 0;
}

.dataList {
  pointer-events: all;
  width: 100%;
  padding: 5px;
  background-color: green;
  color: white;
  margin-bottom: 5px;
}

.typeInput {
  display: flex;
  flex-direction: row;
}

.rangeButton {
  width: 7rem;
  height: 6.5rem;
  border-top: 5px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 5rem 5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: var(--maincolor);
  font-size: 1rem;
}

.rangeButton:hover {
  /* background-size: 5.25rem 5.25rem; */
  /* background-color: red; */
}

.typeSelect {
  width: 21.75rem;
  position: relative;
}

.ranger {
  position: absolute;
  height: 6.5rem;
  padding: 0;
  /* //margin-left:22px; */
  width: 100%;
  opacity: 0;
  margin: 0;
}

.rangeLabels {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.curve {
  background-image: url("../assets/icons_curves.svg");
}

.file {
  background-image: url("../assets/icons_files.svg");
}

.shape {
  background-image: url("../assets/icons_shapes.svg");
}

.rangeActive {
  border-top: 5px solid var(--maincolor);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.editorTools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 31.25rem;
  margin-bottom: 1rem;
}

.inputWrapper {
  width: 23rem;
  overflow: hidden;
  /* overflow: scroll; */
}

.activeSub {
  opacity: 1;
}

.inactiveSub {
  opacity: 0;
}

/* #curveSub{
  left:-50px;
} */
.drawerBox {
  display: none;
}

.effectConsole {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.playConsole {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.effectConsoleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--maincolor);
  height: 2rem;
  width: 7rem;
  /* margin: 3px; */
  overflow: hidden;
  background-size: 1.75rem;
  background-repeat: no-repeat;
  background-position: 0.3rem center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* padding-left: 2.3rem; */
  color: white;
  font-size: 1.25rem;
  font-family: "DillonRegular" Helvetica sans-serif;
}

.effectConsoleButton:hover {
  background-color: var(--hovercolor);
}

.playButton {
  background-image: url("../assets/icons_play.svg");
  /* margin: 28.2rem 0 0 1rem; */
  width: 4rem;
  height: 4rem;
  background-size: 3rem;
  border-radius: 2rem;
  background-position: center;
}

.stopButton {
  background-image: url("../assets/icons_stop.svg");
  /* margin: 28.2rem 0 0 1rem; */
  width: 4rem;
  height: 4rem;
  background-size: 3rem;
  border-radius: 2rem;
  background-position: center;
}

.loopButton {
  background-image: url("../assets/icons_noloop.svg");
  margin: -0.5rem 0 0 0;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
}

.loopButtonActive {
  margin: -0.5rem 0 0 0;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  background-image: url("../assets/icons_loop.svg");
}

.loopButton:hover {
  background-color: transparent;

  opacity: 0.7;
}

#saveButton {
  /* background-image: url("../assets/icons_save.svg"); */
}

#downloadButton {
  display: none;
  /* background-image: url("../assets/icons_save.svg"); */
}

.saveConsoleWrapper {
  display: none;
}



.saveDropDown {
  position: relative;
  /* display: flex;
  flex-direction: column; */
}

.saveBurger {
  width: 2rem;
  height: 2rem;
  /* border-radius: 1rem; */
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../assets/icons_burger.svg");
}

.closeSaveBurger {
  background-image: url("../assets/icons_closeX.svg");
}

.saveBurger:hover {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
}

.saveBurgerMenuButton {
  background-color: rgba(0, 0, 0, 0);
  color: var(--maincolor);
  text-align: left;
}

.saveBurgerMenuButton:hover {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  color: var(--hovercolor);
}

/* .saveBurgerDisabledMenuButton { */
.saveBurgerMenuButton:disabled{
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.3;
  color: var(--maincolor);
}

.saveBurgerDisabledMenuButton:hover {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.3;
  color: var(--maincolor);
}

.saveBurgerMenu {
  z-index: 100;
  left: 3rem;
  top: -4.15rem;
  position: absolute;
  display: none;
  flex-direction: column;
}

.dragModifierHint {
  /* display: none; */
  opacity: 0;
  color: var(--maincolor);
  background-color: white;
  position: absolute;
  left: 26rem;
  top: 15.3rem;
  margin-bottom: 1rem;
  pointer-events: none;
}

.volumeRanger{

  opacity: 0;
  width: 80px;
  height: 20px;
  margin: 0;
  /* margin-top: 0.25rem; */
  transform-origin: 40px 40px;
  transform: rotate(-90deg);
}

.volumeControl{
  /* top:15rem; */
  left:1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;


}

.volumeControlLabel{
  position: absolute;
  left: 1rem;
  top: 2.7rem;
  /* margin-top: -8px;
  margin-left:; */
  width:2rem;
  height: 2rem;
}

.volumeControlLabelOver{
  margin-top: 0px;
  margin-left: 0px;
  width:2rem;
  height: 2rem;
  background-image: url("../assets/icons_volume_00.svg")
}

.volumeControlLabel3{
  position: absolute;
  width:2rem;
  height: 2rem;
  background-image: url("../assets/icons_volume_3.svg")
}
.volumeControlLabel2{
  position: absolute;
  width:2rem;
  height: 2rem;
  background-image: url("../assets/icons_volume_2.svg")
}

.volumeControlLabel1{
  position: absolute;
  width:2rem;
  height: 2rem;
  background-image: url("../assets/icons_volume_1.svg")
}
.volumeControlLabel0{
  position: absolute;
  width:2rem;
  height: 2rem;
  background-image: url("../assets/icons_volume_0.svg")
}

.volumeControlSlider{
  position: absolute;
   top:1.25rem;

   /* 0.5; */
  /* left:0.2rem; */
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: var(--maincolor);

  pointer-events: none;
}

.volumeControlBar{
  position: relative;
   top:-1.15rem ;

  /*left:0.2rem; */
  width: 1rem;
  height: 80px;
  border-radius: 0.5rem;
  background-color: var(--maincolor);
  pointer-events: none;
  opacity: 0.2;
}
.volumeControlValue{
  position: absolute;
  top:1.25rem;
  /* bottom: 177px;
  left:0.2rem; */
  width: 1rem;
  height: 80px;
  border-radius: 0.5rem;
  background-color:  var(--maincolor);

  pointer-events: none;
  opacity: 0.4;


}

.soundControl{
  margin-top: 14.8rem;
  margin-left:1rem;
  display: flex;
  flex-direction: column;
}

.soundControlLabel{
  position: relative;
  left:-0.5rem;
  text-align: left;
  width:3rem;
  margin-bottom: 0rem;
}
.channelSelector{
  color: var(--maincolor);
  position: relative;
  top:-0.75rem;
  left:1.5rem;
  height:5rem;

}

.leftSide{
  position: absolute;
  width: 2rem;
  top: 14.625rem;
  display: flex;
  flex-direction: row;
}

.yAxis {
  position: absolute;
  width: 2rem;
  top: 14.625rem;
  right: 31.75rem;
  height: 31.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: grey;
  opacity: 0.5;
  font-family: "DillonMedium";
}

.yAxis small {
  padding: 0;
  width: auto;
  text-align: right;
}

.yAxis small:after {
  /* content: " —"; */
}


.flipTools{
  margin-top: 14.1rem;
  position: relative;
      left: 21.5rem;
}


.flip{
  width:1.4375rem;
  height:1.4375rem;
  text-align: center;
  margin-top: 0.5rem;
}

.flip:hover{
  opacity: 0.7;

}

.flipH{
  margin-top: 0.6rem;

  background-image: url("../assets/icons_flipH.svg");

}

.flipV{
  background-image: url("../assets/icons_flipV.svg");
}

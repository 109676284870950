.fileContainer {
  display: flex;
  flex-direction: row;
  pointer-events: all;
  /* margin-right: 2em; */
  height: 100%;
  overflow: hidden;
  margin-top: 8rem;

  /* padding: 0.5em; */
}

.allEffects {
  display: none;
}

.effectList {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 18rem;
  overflow: scroll;
  /* scroll-padding-top: 1rem; */
  box-sizing: content-box;
}

.effectListHeader {
  height: 1.7rem;
  width: 16.25rem;
  margin: 2rem 1.75rem 0 2rem;
  font-family: "DillonMediumItalic", Helvetica, sans-serif;
  font-size: 0.875rem;
  border-bottom: 2px black solid;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.effectListHeader h2 {
  margin: 0;
}

.maskShader {
  position: fixed;
  height: 0.0625rem;

  //margin-top: 17.9375rem;
  background-color: #666;
  /* background-image: url("../assets/maskShader.png"); */
  width: 16.25rem;
  margin: 100% 2rem 0 2rem;
  /* margin: 17.9375rem 2rem 0 2rem; */

  /* border-bottom: 2px black solid; */
}

.userEffects {
  /* max-height: 50%; */
}

.effectListItem {
  min-height: 4rem;
  /* pointer-events: ; */
  width: 15.25rem;
  padding: 0 0.5rem 0 0.5rem;
  margin: 0 1.75rem 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px lightgrey solid;
}

.emptyeEffectListItem {
  min-height: 4rem;
  width: 15.25rem;
  padding: 0 0.5rem 0 0.5rem;
  margin: 0 1.75rem 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.aboveActiveEffectListItem {
  border-bottom: 1px solid white;
}

.activeEffectListItem {
  margin: 0;
  border-bottom: 1px solid var(--subcolor);
  background-color: var(--subcolor);
  width: 19rem;
  justify-content: center;
}

.activeEffectListItem .effectListItemLabel {
  margin: 0 0rem 0 0.24rem;
}

.effectListItemLabel {
  position: relative;
  font-size: 1rem;
  width: auto;
  width: 10.75rem;
  padding: 0.25rem;

  /* margin: 0.3em; */
}

.myEffectLabel {
  width: 8.05rem;
}

.effectTitle {
  margin: 0;
  margin-left: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.beforePrimitive:before {
  position: absolute;
  left: 0;
  top: 0;
  content: url("../assets/icons_effectTitleBeforePrimitive.svg");
}

.beforeFile:before {
  position: absolute;
  left: 0;
  top: 0.08rem;
  content: url("../assets/icons_effectTitleBeforeFile.svg");
}

.beforeDraw:before {
  position: absolute;
  left: 0;
  top: 0.05rem;
  content: url("../assets/icons_effectTitleBeforeDraw.svg");
}

.effectDate {
  opacity: 0.4;
  font-size: 0.95rem;
}
.effectListItemTool {
  width: 2em;
  height: 2em;
  background-color: var(--maincolor);
  border-radius: 1em;
}

.effectListItemTool:hover {
  background-color: var(--hovercolor);
}

.effectListItemToolPlay {
  background-image: url("../assets/icons_play.svg");
}

.effectListItemToolEdit {
  background-image: url("../assets/icons_pencilWhite.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.effectListItemToolDelete {
  background-image: url("../assets/icons_dustbinWhite.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.listCollapse {
  width: 1rem;
  height: 1rem;
  background-image: url("../assets/icons_collapse.svg");
}

.listExpand {
  background-image: url("../assets/icons_expand.svg");
}


.fileContainerUpdate{
  margin-top: 12rem;
  margin-left:2rem;
  width:100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center
}

.updateHead{
  margin-left: 0.5rem
}

.loadingIndicatorBallHand {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 0.5rem;
  /* position: absolute; */
  background-color: var(--maincolor);
  animation: updateHand 1s infinite ease;
}

.loadingIndicatorBall {
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  animation: updateBall 1s infinite linear;
  margin-bottom: 0.5rem;
  /* background-color: red; */
}

@keyframes updateBall {
  from {
    transform: rotate(45deg);
    /* transform: opacity(0.2); */
  }
  to {
    transform: rotate(403deg);
    /* transform: opacity(1); */
  }
}

@keyframes updateHand {
  0%,
  100% {
    /* background-color: var(--subcolor); */
    opacity: 0;
    width: 0.325rem;
    height: 0.325rem;
  }
  50% {
    /* background-color: var(--maincolor); */
    opacity: 1;
    /* width: 1rem;
    height: 1rem; */
  }
  75% {
    /* background-color: var(--maincolor); */
    /* opacity: 1; */
    width: 0.5rem;
    height: 0.5rem;
  }
}

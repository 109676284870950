.myInput {
  /* margin-bottom: 1em; */
  width: 7rem;
  margin-right: 0.25rem;
  display: flex;
  flex-direction: column;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-top: -1rem;
  padding-left: 0.5rem;
  width: 7rem;
  background-image: url("../assets/icons_inputArrow.svg");
  background-repeat: no-repeat;
  background-position: right;
  font-family: "DillonRegular";
  font-size: 1.25rem;
  color: white;
  border-radius: 0;
  border: none;
  height: 2rem;
  background-color: var(--maincolor);
}

select:hover {
  background-color: var(--hovercolor);
}

.inputLabel {
  font-size: 0.75rem;
  color: var(--maincolor);
  padding: 0.125rem 0 0 0.5rem;
  height: 1rem;
}

.selectWrapper::before {
  position: absolute;
  font-family: "DillonRegular";
  font-size: 1.25rem;
  color: white;
}

select:focus {
  outline: none;
}

.overlayText{
  position: relative;
  /* opacity: 0.5; */
  /* background-color: red; */
  top:-28px;
  left:3px;
  width:40%;
  height:22px;
  border-radius: 2px;
  border:0px solid;
  padding-left:7px;
  padding-right:7px;
  font-size: 1.25rem;
  color: var(--maincolor);
  font-family: "DillonRegular";
}



input:focus{
  outline:none
}

.multipleWrapper{
  height:2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--maincolor);
  font-size: 1.25rem;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--maincolor);
  font-size: 1.25rem;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--maincolor);
  font-size: 1.25rem;
}

.radioButtonbar{
  margin-top: 0.3rem;
}

.radioButtonGroup{
    display: flex;
    flex-direction: column;
}

.complexRadiobuttonGroup{
  display: flex;
  flex-direction: row;
}

.raudioButtonColumn{
  margin-right: 1.5rem;
}

.radioButton{
  margin-bottom: 0.5rem;
  width:1rem;
  height: 1rem;
  /* border-radius: 0.5rem; */
  /* border: 2px var(--maincolor) solid; */
  background-color: var(--subcolor);
  color:var(--maincolor);
  font-size: 0.75rem;
}


.radioContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-top: 0;
  padding-left: 1.3rem;
  margin-bottom: 0.4rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  height: 0;
  width: 0;

}

/* Create a custom radio button */
.radiocheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: var(--subcolor);
  border-radius: 50%;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  border: 0.125rem solid var(--maincolor);
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .radiocheckmark {
  background-color: var(--maincolor);
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .radiocheckmark {
  background-color: var(--maincolor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiocheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .radiocheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .radiocheckmark:after {
  top: -0.125rem;
  left: -0.125rem;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  border-radius: 50%;
  border: 0.25rem solid var(--maincolor);
  background: var(--subcolor);
}


.errorWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorContent{
  text-align: center;
  width: 30%;
  height:auto;
}

.saveConsole {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
}

.saveHead {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.saveForm {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  align-items: center;
}

.nameInput {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.tagInput {
  display: none;
}

.processIndicator {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.processIndicatorBall {
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  animation: processBall 1s infinite linear;
  margin-bottom: 1rem;
  /* background-color: red; */
}

.processIndicatorBallHand {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  /* position: absolute; */
  background-color: var(--maincolor);
  animation: processHand 1s infinite ease;
}

@keyframes processBall {
  from {
    transform: rotate(45deg);
    /* transform: opacity(0.2); */
  }
  to {
    transform: rotate(403deg);
    /* transform: opacity(1); */
  }
}

@keyframes processHand {
  0%,
  100% {
    /* background-color: var(--subcolor); */
    opacity: 0;
    width: 0.75rem;
    height: 0.75rem;
  }
  50% {
    /* background-color: var(--maincolor); */
    opacity: 1;
    /* width: 1rem;
    height: 1rem; */
  }
  75% {
    /* background-color: var(--maincolor); */
    /* opacity: 1; */
    width: 1rem;
    height: 1rem;
  }
}

.buttonBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.curveCanvas {
  background-color: white;
  width: 500px;
  height: 500px;
  border: 1px solid white;
  position: relative;
  pointer-events: click;
  overflow: visible;
}

.envelopelement {
  position: absolute;
  top: 0;
}

.canvas {
  /* background-color: white; */
  /* opacity: 0.5; */
  margin-top: -2px;
}
.eCanvas {
  /* margin-top: -20px;
  width: 500px;
  height: 255px;
  background-color: rgba(20, 0, 20, 0.1); */
}

/*.handle{
  position: absolute;
  width:10px;
  height: 10px;
  border-radius: 5px;
  background-color:red
} */

:root {
  --maincolor: rgb(47, 20, 255);
  --subcolor: rgb(237, 233, 255);
  --subcolor2: rgb(237, 233, 255);
  --hovercolor: rgb(87, 65, 255);
  --hovercolor2: rgb(244, 242, 255);

height: 100%;
}

#root{
  height: 100%;

}


@font-face {
  font-family: "DillonRegular";
  src: url("../assets/font/FSDillonWeb-Regular.woff") format("woff"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "DillonMedium";
  src: url("../assets/font/FSDillonWeb-Medium.woff") format("woff"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "DillonMediumItalic";
  src: url("../assets/font/FSDillonWeb-MediumItalic.woff") format("woff"); /* IE9 Compat Modes */
}

html {
  font-family: "DillonRegular", Times, serif;
  line-height: 1.25rem;
  background-color: white
}

body{
  background-color: white;
  /* var(--subcolor); */
  height: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
}

p {
  line-height: 1.25rem;
}

a {
  font-size: 1rem;
  text-decoration: none;
  color: var(--maincolor);
}

.myform-control {
  font-size: 0.75rem;
}

.login {
  margin-left: 0rem;
  /* margin-bottom:1rem; */
  padding-left: 3rem;
  background-color: white;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-image: url("../assets/op_actuators004.png");
  background-repeat: no-repeat;
  background-position: right top;
}

.loginForm {
  width: 18.125rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.logInInput {
  /* width: 18.125rem; */
  background-color: var(--subcolor);
  border: none;
  height: 4rem;
  padding: 0 1rem 0 1rem;
  margin-bottom: 2rem;
  font-size: 0.9em;
}

.logInInput::placeholder {
  font-size: 1rem;
  font-family: "DillonMediumItalic", Helvetica, sans-serif;
  text-decoration: none;
  color: black;
}

.pass {
  position: relative;
}

.pass input {
  /* width: 100%; */
}

.passButton {
  opacity: 0.5;
  position: absolute;
  border: none;
  right: 0.725rem;
  width: 15%;
  height: 4rem;
  bottom: 8rem;
  background-color: var(--subcolor);
  background-image: url("../assets/icons_showHidePass.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

.signInPassButton {
  bottom: 2rem;
}

.passButton:focus,
.submitLogin:focus {
  outline: 0;
}

.submitLogin {
  border: none;
  background-color: var(--maincolor);
  color: white;
  font-family: "DillonRegular", Helvetica, sans-serif;
  width: 12rem;
  min-height: 3rem;
  /* padding: 0 0.1rem 0 1rem; */
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 2rem;
  justify-content: center;
}

#signup,
#upHint {
  display: none;
}

#outHint{
  margin-top: 1rem;
}

.hint {
  background: none!important;
  border: none;
  padding: 0!important;
  font-size: 1rem;
  text-decoration: none;
  text-align: left;
  width:auto;
  height:auto;
  color: var(--maincolor);
  margin-bottom:1rem;

}

.hint:hover{
  cursor: pointer;
  color: var(--hovercolor)
}

.masterElement {
  opacity: 1;
  transition: 0.5s 0.3s;
}

.profile {
  margin: 0;
  position: fixed;
  top: 2rem;
  left: 2rem;
  font-size: 1.5rem;
}

.profile p {
  line-height: 1.8rem;
}

.profile a {
  font-size: 1.2rem;
  padding-left: 2.2rem;
}

.profile a:before {
  position: absolute;
  left: 0;
  background-image: url("../assets/icons_logOut.svg");
  background-size: 2rem 2rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  content: "";
}

.signInHeader {
  font-family: "DillonRegular", Helvetica, sans-serif;
  font-size: 5.625rem;
  font-weight: normal;
  margin: 0;
  margin-bottom: 4rem;
}

.mainHeader {
  /* display: flex;
  flex-direction: row; */
}

.introText {
  position: absolute;
  padding-top: 2rem;
  left: 20.125rem;
  margin-left: 8rem;
  border-top: 2px solid black;
  width: 22rem;
}

.confirmSignin {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confiremSigninContent {
  width: 50%;
}

.confiremSigninContent > h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.termsAndDisclaim{

  display: flex;
  flex-direction: row;
  width: 10rem;
  justify-content: space-between;
}

.textButton{
  font-size: 1rem;
  height:1rem;
  text-decoration: none;
  color: var(--maincolor);
  border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}


.textButton:hover{
  color: var(--hovercolor);
  border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
